/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Settings } from 'luxon';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import rrulePlugin from '@fullcalendar/rrule';
import allLocales from '@fullcalendar/core/locales-all';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { useLocation, useNavigate } from 'react-router-dom';
import luxon2Plugin from '@fullcalendar/luxon2';
import { Stack, TextStyle, Tooltip, Toast, Filters } from '@shopify/polaris';
import './Calendar.scss';
import EventEdit from './EventEdit';
import NewEvent from './NewEvent';
import RecurringEventDeleteConfirmation from './RecurringEventDeleteConfirmation';
import DisplayEvent from './DisplayEvent';
import DatePickerModal from './DatePickerModal';
import ModalService from '../Shared/Services/ModalService';
import ConfirmationModal from '../Shared/ConfirmationModal/ConfirmationModal';
import useDebounce from '../../hooks/useDebounce';
import { stateConverter } from '../FilterEngine/filterParams';
import { handleFilterChange } from '../FilterEngine/FilterEngine';

// Used for dialog Modal
const confirmationModalRef = React.createRef();

const Calendar = (props) => {
  const [loading, setLoading] = useState(false);
  const [currentSelect, setCurrentSelect] = useState(new Date());
  const [calendarViewDates, setCalendarViewDates] = useState('');
  const [showEventEdit, setShowEventEdit] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [calendarType, setCalendarType] = useState('');
  const [displayEventDetails, setDisplayEventDetails] = useState(false);
  const [showNewEvent, setShowNewEvent] = useState(false);
  const [newEventData, setNewEventData] = useState({});
  const [resources, setResources] = useState([]);
  const [eventToEdit, setEventToEdit] = useState({});
  const [events, setEvents] = useState([]);
  const [calendarSettings, setCalendarSettings] = useState({});
  const [currentEvent, setCurrentEvent] = useState({});
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showRecurringEventDeleteConfirmation, setShowRecurringEventDeleteConfirmation] = useState(false);

  const calendarRef = useRef();

  const { t } = useTranslation();
  const location = useLocation();
  const history = useNavigate();

  const [toastMessage, setToastMessage] = useState('');
  const [toastActive, setToastActive] = useState(false);
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={3000} />
  ) : null;

  const eventModifier = (e) => {
    e.start = new Date(e.start);
    e.end = new Date(e.end);
    return e;
  };

  const [tableFilters, setTableFilters] = useState(stateConverter(location));
  const debouncedSearch = useDebounce(tableFilters, 400);
  const filters = [];

  const filterControl = (
    <Filters
      queryValue={tableFilters.queryValue}
      filters={filters}
      onQueryChange={handleFilterChange({ history, location }, setTableFilters, 'queryValue')}
      onQueryClear={handleFilterChange({ history, location }, setTableFilters, 'queryValue', 'reset', '')}
      onClearAll={() => {}}
      appliedFilters={[]}
      queryPlaceholder={t('user.search')}
    />
  );

  const fetchStaffAccounts = useCallback(() => {
    setLoading(true);

    if (props.isStaff) return;

    axios.get('/v1/staffs/calendar_resources')
      .then((res) => {
        const temp = [{ id: props.token, title: props.name, groupId: t('calendar.head') }];
        setResources([...temp, ...res.data.staff_accounts]);
      })
      .catch(() => toggleToastActive(t('shared.something_went_wrong')))
      .then(() => {
        setLoading(false);
      });
  // eslint-disable-next-line
  }, [props.name, props.isStaff, props.token, debouncedSearch]);

  useEffect(() => {
    Settings.defaultZoneName = calendarSettings.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;
  }, [calendarSettings.timeZone]);

  useEffect(() => {
    fetchStaffAccounts();
  }, [fetchStaffAccounts]);

  const fetchEvents = useCallback(() => {
    const start = calendarViewDates.dig('start', { default: '' }).toString();
    const end = calendarViewDates.dig('end', { default: '' }).toString();

    if (!start) return;

    setLoading(true);

    axios.post('/v1/events/search', { start, end, type: calendarType, search: tableFilters.queryValue })
      .then((response) => {
        setCalendarSettings(response.data.calendarSettings);
        response.data.events.forEach((e) => {
          eventModifier(e);
        });
        setEvents(response.data.events);
      })
      .catch(() => {
        toggleToastActive(t('shared.something_went_wrong'));
      })
      .then(() => {
        setLoading(false);
      });
  // eslint-disable-next-line
  }, [calendarViewDates, debouncedSearch]);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  const newEvent = (event) => {
    setNewEventData(event);
    setShowNewEvent(true);
  };

  const handleCreateNewEvent = async (event) => {
    const data = {
      event: { ...event },
      start: calendarViewDates.start,
      end: calendarViewDates.end,
      resource_id: event.resource?.id
    };

    if (data.event.allDay) {
      data.event.all_day = true;
    }

    data.event.start_time = data.event.start;
    data.event.end_time = data.event.end;

    setLoading(true);

    if (data.event.signedInParticipations?.length) {
      data.notify = await confirmationModalRef.current.open({
        title: t('notifications.notify_participants'),
        description: t('notifications.send_email_notification'),
        cancelButton: t('shared.no'),
        submitButton: t('notifications.notify')
      });
    }

    axios.post('/v1/events', data)
      .then(() => {
        fetchEvents();
        toggleToastActive(t('calendar.new_event_created'));
      })
      .catch((err) => {
        if (err.response.data?.exception?.includes('Select less')) {
          toggleToastActive(t('calendar.create_less_events'));
        } else {
          toggleToastActive(t('shared.something_went_wrong'));
        }
      })
      .then(() => {
        setLoading(false);
      });
  };

  const moveEvent = (info) => {
    const { id, start, end } = info.event;
    const data = {
      category: 'move',
      token: id,
      start_time: start,
      end_time: end,
      meta: info.event.extendedProps.meta,
      old_resource: info.oldResource?.id?.split('-')[0],
      new_resource: info.newResource?.id?.split('-')[0]
    };
    updateEvent(data);
  };

  const updateEvent = async (event) => {
    if (!event.dig('meta', 'show_title')) {
      // eslint-disable-next-line
      // event.title = '';
    }

    if (event.allDay) {
      // eslint-disable-next-line
      event.all_day = true;
    }

    // eslint-disable-next-line
    event.notify = await confirmationModalRef.current.open({
      title: t('notifications.notify_participants'),
      description: t('notifications.send_email_notification'),
      cancelButton: t('shared.no'),
      submitButton: t('notifications.notify')
    });

    if (event.id) {
      // eslint-disable-next-line
      event.token = event.id;
    } else {
      // eslint-disable-next-line
      event.id = event.token;
    }

    setLoading(true);

    axios.patch(`/v1/events/${event.id}`, event)
      .then((response) => {
        toggleToastActive(t('shared.saved'));
        const idx = events.findIndex((e) => e.id == event.token);
        const temp = [...events];
        temp[idx] = eventModifier(response.data);
        setEvents(temp);
      })
      .catch(() => {
        toggleToastActive(t('shared.unable_to_save'));
      })
      .then(() => {
        setLoading(false);
      });
  };

  const handleDeleteEvent = async (event, type) => {
    setShowRecurringEventDeleteConfirmation(false);

    if (type === 'one') {
      setLoading(true);

      let notify = false;

      if (event.signedInParticipations?.length) {
        // eslint-disable-next-line
        notify = await confirmationModalRef.current.open({
          title: t('notifications.notify_participants'),
          description: t('notifications.send_email_notification'),
          cancelButton: t('shared.no'),
          submitButton: t('notifications.notify')
        });
      }

      axios.delete(`/v1/events/${event.id}`, {
        data: {
          notify
        }
      })
        .then(() => {
          toggleToastActive(t('shared.deleted'));

          const idx = events.findIndex((e) => e.id == event.id);
          const temp = [...events];
          temp.splice(idx, 1);
          setEvents(temp);
        })
        .catch(() => {
          toggleToastActive(t('shared.unable_to_save'));
        })
        .then(() => {
          setLoading(false);
        });
    }

    if (type === 'all') {
      const params = {
        start: calendarViewDates.start,
        end: calendarViewDates.end
      };

      if (event.signedInParticipations?.length) {
        // eslint-disable-next-line
        params.notify = await confirmationModalRef.current.open({
          title: t('notifications.notify_participants'),
          description: t('notifications.send_email_notification'),
          cancelButton: t('shared.no'),
          submitButton: t('notifications.notify')
        });
      }

      setLoading(true);

      axios.post(`/v1/events/${event.id}/delete_all`, params)
        .then((response) => {
          toggleToastActive(t('shared.deleted'));
          setEvents(response.data.events);
        })
        .catch(() => {
          toggleToastActive(t('shared.unable_to_save'));
        })
        .then(() => {
          setLoading(false);
        });
    }
  };

  const handleDelete = (event) => {
    if (event.recurring) {
      setCurrentEvent(event);
      setShowRecurringEventDeleteConfirmation(true);
    } else {
      if (!window.confirm(t('calendar.are_you_sure'))) {
        return;
      }
      setCurrentEvent(event);
      handleDeleteEvent(event, 'one');
    }
  };

  const handleDeleteAll = () => {
    handleDeleteEvent(currentEvent, 'all');
    setCurrentEvent({});
    setShowRecurringEventDeleteConfirmation(false);
  };

  const editEvent = (event) => {
    const updatable = {
      start: event.event.start,
      end: event.event.end,
      id: event.event.id,
      title: event.event.title,
      price: event.event.extendedProps.price,
      description: event.event.extendedProps.description,
      participations: event.event.extendedProps.participations,
      signedInParticipations: event.event.extendedProps.signedInParticipations,
      participation_limit: event.event.extendedProps.participationLimit,
      recurring: event.event.extendedProps.recurring,
      online: event.event.extendedProps.online,
      meta: event.event.extendedProps.meta
    };
    setEventToEdit(updatable);
    setDisplayEventDetails(true);
  };

  const closeModal = () => {
    setShowNewEvent(false);
    setShowEventEdit(false);
    setDisplayEventDetails(false);
  };

  const editableEvent = () => {
    setShowEventEdit(true);
    setDisplayEventDetails(false);
  };

  const displayEventModal = displayEventDetails
    ? (
      <DisplayEvent
        active={displayEventDetails}
        close={closeModal}
        handleSubmit={editableEvent}
        handleDelete={handleDelete}
        event={eventToEdit}
      />
    ) : null;

  let datePickerModal = null;

  if (showDatePicker) {
    datePickerModal = (
      <DatePickerModal
        currentSelect={currentSelect}
        setCurrentSelect={setCurrentSelect}
        calendarRef={calendarRef}
        active={showDatePicker}
        date={calendarViewDates}
        setDates={setCalendarViewDates}
        close={() => setShowDatePicker(false)}
      />
    );
  }

  const eventEditModal = showEventEdit
    ? (
      <EventEdit
        active={showEventEdit}
        close={closeModal}
        handleSubmit={updateEvent}
        handleDelete={handleDelete}
        event={eventToEdit}
      />
    ) : null;

  const newEventModal = showNewEvent
    ? (
      <NewEvent
        active={showNewEvent}
        close={closeModal}
        handleSubmit={handleCreateNewEvent}
        event={newEventData}
      />
    ) : null;

  const resizeEvent = (info) => {
    const data = {
      token: info.event.id,
      start_time: info.event.start,
      end_time: info.event.end,
      category: 'move',
      meta: info.event.extendedProps.meta,
      old_resource: info.oldResource?.id?.split('-')[0],
      new_resource: info.newResource?.id?.split('-')[0]
    };

    updateEvent(data);
  };

  const viewWillUnmount = () => {
    setEvents([]);
  };

  const initialView = () => {
    const width = window.innerWidth;
    if (width > 768) {
      return 'timeGridWeek';
    }

    return 'timeGridDay';
  };

  const setCalendarNavigation = () => {
    const width = window.innerWidth;
    if (width > 768) {
      return props.isStaff ? 'dayGridMonth,timeGridWeek,timeGridDay' : 'dayGridMonth,timeGridWeek,timeGridDay,resourceTimelineDay,resourceTimelineMonth';
    }

    return props.isStaff ? 'dayGridMonth,timeGridDay' : 'dayGridMonth,timeGridDay,resourceTimelineDay,resourceTimelineMonth';
  };

  const updateCalendarDates = (data) => {
    const start = data.dig('view', 'activeStart');
    const end = data.dig('view', 'activeEnd');
    setCalendarViewDates({ start, end });
    setCalendarType(data.view.type);
    fetchEvents(start, end, data.view.type);
  };

  const viewDidMount = (data) => {
    const start = data.dig('view', 'activeStart');
    const end = data.dig('view', 'activeEnd');
    setCalendarViewDates({ start, end });
    setCalendarType(data.view.type);
    fetchEvents(start, end, data.view.type, true);
  };

  const setCalendarRange = (data) => {
    setCalendarViewDates({
      start: data.view.activeStart,
      end: data.view.activeEnd
    });
    setCalendarType(data.view.type);
  };

  const recurringEventDeleteConfirmation = showRecurringEventDeleteConfirmation ? (
    <RecurringEventDeleteConfirmation
      active={showRecurringEventDeleteConfirmation}
      close={() => setShowRecurringEventDeleteConfirmation(false)}
      handleChangeDeleteAll={() => handleDeleteAll()}
      handleChangeDeleteOnlyThisOne={() => handleDeleteEvent(currentEvent, 'one')}
    />
  ) : null;

  const checkSpotRemaining = (data) => data.cancelledClient && (data.spotsRemaining || data.spotsRemaining === null);

  const calendarForm = (
    <FullCalendar
      ref={calendarRef}
      schedulerLicenseKey="0924439612-fcs-253339312338"
      // resources={[
      //   {
      //     id: 'a',
      //     title: 'Room A'
      //   },
      //   {
      //     id: 'b',
      //     title: 'Room B'
      //   }
      // ]}
      resourceAreaColumns={[{
        headerContent: t('staff.staff'),
        field: 'title'
      }, {
        headerContent: 'Tags',
        field: 'tags'
      }]}
      timeZone={calendarSettings.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone}
      resourceGroupField="groupId"
      resourceOrder=""
      resources={resources}
      // resourceGroupField="Staff"
      // events="https://fullcalendar.io/demo-events.json?single-day&for-resource-timeline"
      firstDay={parseInt((calendarSettings.firstDay || '0'), 10)}
      slotMinTime={calendarSettings.minTime || '00:00:00'}
      slotMaxTime={calendarSettings.maxTime || '24:00:00'}
      businessHours={calendarSettings.businessHours}
      weekends={calendarSettings.weekends}
      nowIndicator
      // expandRows
      viewWillUnmount={viewWillUnmount}
      viewDidMount={viewDidMount}
      height="auto"
      datesSet={updateCalendarDates}
      slotLabelDidMount={setCalendarRange}
      locales={allLocales}
      contentHeight={800}
      locale={props.lang}
      initialView={initialView()}
      headerToolbar={{
        left: 'prev,next',
        center: 'datePickerButton',
        right: setCalendarNavigation()
      }}
      customButtons={{
        datePickerButton: {
          text: calendarRef.current?.getApi()?.currentDataManager?.data?.viewTitle,
          click: () => setShowDatePicker(true)
        }
      }}
      views={{
        dayGrid: { titleFormat: { year: 'numeric', month: 'short', day: '2-digit' } },
        timeGrid: { titleFormat: { year: 'numeric', month: 'short', day: '2-digit' } },
        week: { titleFormat: { year: 'numeric', month: 'short', day: '2-digit' } },
        day: { titleFormat: { year: 'numeric', month: 'short', day: '2-digit' } },
        resourceTimelineDay: { type: 'resourceTimelineDay', buttonText: t('calendar.staff_day') },
        resourceTimelineMonth: { type: 'resourceTimelineMonth', buttonText: t('calendar.staff_month') }
      }}
      plugins={[
        luxon2Plugin,
        rrulePlugin,
        dayGridPlugin,
        timeGridPlugin,
        listPlugin,
        interactionPlugin,
        resourceTimelinePlugin
      ]}
      editable
      selectable
      select={newEvent}
      eventResize={resizeEvent}
      events={events}
      // eslint-disable-next-line react/no-unstable-nested-components
      // eventContent={(args) => (
      //   <div>
      //     <div className="flex">
      //       {/* {args.event.extendedProps.online ? ( */}
      //       <Tooltip content="Online Event">
      //         <span className="dot" />
      //       </Tooltip>
      //       {/* ) : ''} */}
      //       {/* {checkSpotRemaining(args.event.extendedProps) ? ( */}
      //       <Tooltip content={`${t('event.open_spots_and_client_cancellation')}`}>
      //         <span className="red-dot" />
      //       </Tooltip>
      //       {/* ) : ''} */}
      //       <div className="flex-end">
      //         <TextStyle>{args.timeText}</TextStyle>
      //       </div>
      //     </div>
      //     <div className="flex">
      //       <Tooltip
      //         content={args.event.extendedProps.temp ? (
      //           <TextStyle>{t('booking.temporary')}</TextStyle>
      //         ) : args.event.title}
      //       >
      //         <TextStyle>{args.event.title}</TextStyle>
      //       </Tooltip>
      //     </div>
      //   </div>
      // )}
      eventClick={editEvent}
      eventDrop={moveEvent}
    />
  );

  return (
    <div className="bg-white">
      <ModalService ref={confirmationModalRef}>
        <ConfirmationModal />
      </ModalService>
      {recurringEventDeleteConfirmation}
      {toastMarkup}
      {displayEventModal}
      {eventEditModal}
      {datePickerModal}
      {newEventModal}
      {calendarForm}
      <footer className="bg-white">
        <div className="pt-4">{filterControl}</div>
        <div className="max-w-7xl mx-auto py-12 px-4 items-center justify-between">
          <div className="mt-8 md:mt-0">
            <p className="text-center text-base text-gray-400">{`TZ - ${props.timezone}`}</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  name: state.auth.name,
  token: state.auth.token,
  lang: state.auth.lang,
  timezone: state.auth.timezone,
  isStaff: state.auth.role === 'staff'
});

export default connect(mapStateToProps)(Calendar);
