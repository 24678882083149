/* eslint-disable no-unused-vars */
import axios from 'axios';
import React, { useEffect, useState, useCallback } from 'react';
import { Filters, Card, SkeletonPage, Page, DataTable, EmptyState, Button, Popover, ActionList } from '@shopify/polaris';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';
import useDebounce from '../../hooks/useDebounce';
import { stateConverter } from '../FilterEngine/filterParams';
import { handleFilterChange, searchFilterToURL, appliedFiltersQuery } from '../FilterEngine/FilterEngine';
import TablePagination from '../FilterEngine/TablePagination/TablePagination';
import { defaultSortDirection, handleSort, initialSortColumnIndex } from '../FilterEngine/Sort/sort';
import { ReactFormGenerator } from '../Shared/FormBuilder';
import ViewForm from '../Shared/FormBuilderV2/ViewForm';

const FilledOutForms = () => {
  const [viewReply, setViewReply] = useState(null);
  const [loading, setLoading] = useState(true);
  const [records, setRecords] = useState([]);
  const [mounted, setMounted] = useState(true);
  const [init, setInit] = useState(false);
  const [lastPage, setLastPage] = useState(null);

  const { t } = useTranslation();
  const history = useNavigate();
  const location = useLocation();
  const matchParams = useParams();

  const [tableFilters, setTableFilters] = useState(stateConverter(location));
  const debouncedSearch = useDebounce(tableFilters, 400);

  const filters = [];

  const appliedFilters = useCallback(() => {
    const af = [];

    return af;
  }, []);

  const openReply = (row) => {
    setViewReply({
      data: JSON.parse(row.data),
      answerData: JSON.parse(row.answerData),
      createdAt: row.createdAt
    });
  };

  const modifier = (tableData) => tableData.map((row, i) => {
    const modifiedRow = [];

    modifiedRow.push(row.title);
    modifiedRow.push(moment(row.createdAt).format('LLLL'));
    modifiedRow.push(<Button onClick={() => openReply(row)}>{t('suvey.view')}</Button>);

    return modifiedRow;
  });

  const fetchData = useCallback(() => {
    const params = {
      per_page: 30,
      page: tableFilters.page,
      search: tableFilters.queryValue,
      status: tableFilters.tab === 'All' ? '' : tableFilters.tab,
      q: appliedFiltersQuery(appliedFilters())
    };

    axios.post('/v1/surveys/user_replies', params)
      .then((res) => {
        if (mounted) {
          setRecords(res.data.survey_answers);
          setLastPage(res.data.lastPage);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  // eslint-disable-next-line
  }, [debouncedSearch]);

  const filterControl = (
    <Filters
      queryValue={tableFilters.queryValue}
      filters={filters}
      onQueryChange={handleFilterChange({ history, location }, setTableFilters, 'queryValue')}
      onQueryClear={handleFilterChange({ history, location }, setTableFilters, 'queryValue', 'reset', '')}
      onClearAll={handleFilterChange({ history, location }, setTableFilters, '/surveys', 'resetAll')}
      appliedFilters={appliedFilters()}
      queryPlaceholder={t('user.search')}
    />
  );

  useEffect(() => {
    setMounted(true);
    fetchData();
    return () => {
      setMounted(false);
    };
  }, [fetchData]);

  useEffect(() => {
    if (init) {
      setTableFilters((f) => ({ ...f, page: 1 }));
      searchFilterToURL({ history, location }, 'page', null, 'delete');
    }
    setInit(true);
  // eslint-disable-next-line
  }, [tableFilters.queryValue, tableFilters.role, tableFilters.userType]);

  const indexSortMapping = {
    status: true,
    created_at: false
  };

  const headings = [
    t('shared.title'),
    t('survey.completed_on'),
    ''
  ];

  const content = (
    <DataTable
      columnContentTypes={[
        'text',
        'text',
        'text',
        'text',
        'text'
      ]}
      verticalAlign="middle"
      headings={headings}
      rows={modifier(records)}
      sortable={Object.values(indexSortMapping)}
      defaultSortDirection={defaultSortDirection(tableFilters.sorts)}
      initialSortColumnIndex={initialSortColumnIndex(indexSortMapping, tableFilters.sorts)}
      onSort={handleSort({ location, history }, setTableFilters, indexSortMapping)}
    />
  );

  return (
    <Page
      title={t('survey.filled_out_forms_etc')}
      fullWidth
      separator
      narrowWidth={viewReply}
      breadcrumbs={viewReply ? [{ content: 'Back', onAction: () => setViewReply(null) }] : []}
    >
      {viewReply ? (
        <Card sectioned>
          {new Date(viewReply.createdAt) < new Date('2024-10-4') ? (
            <ReactFormGenerator
              read_only
              hide_actions
              data={viewReply.data || []}
              answer_data={viewReply.answerData || []}
            />
          ) : <ViewForm reply={viewReply.answerData || []} form_data={viewReply?.data || []} theme={viewReply?.theme} />}
        </Card>
      ) : (
        <>
          {!loading ? (
            <Card sectioned>
              <Card.Subsection>
                {filterControl}
              </Card.Subsection>
              <Card.Subsection>
                {content}
              </Card.Subsection>
            </Card>
          ) : <SkeletonPage />}
          <TablePagination
            pageFilter={tableFilters.page}
            setTableFilters={setTableFilters}
            records={records}
            lastPage={lastPage}
          />
        </>
      )}
    </Page>
  );
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.admin
});

export default connect(mapStateToProps)(FilledOutForms);
