/* eslint-disable eqeqeq */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Page, Layout, Card, DescriptionList, Stack, Button, ResourceItem, TextStyle, ResourceList, Toast, Avatar, Badge } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';
import { BlockMinor, ChecklistMajor, ProductsMinor } from '@shopify/polaris-icons';
import Banners from '../Shared/Banners';
import useDebounce from '../../hooks/useDebounce';
import Comments from '../Comments/Comments';
import classes from '../FilterEngine/TablePagination/TablePagination.module.scss';
import { stateConverter } from '../FilterEngine/filterParams';
import TablePagination from '../FilterEngine/TablePagination/TablePagination';
import ParticipantsResourceList from '../Participations/ParticipantsResourceList';
import EventEdit from '../Calendar/EventEdit';
import ProductBookings from '../ProductBookings/ProductBookings';
import RecurringEventDeleteConfirmation from '../Calendar/RecurringEventDeleteConfirmation';
import ModalService from '../Shared/Services/ModalService';
import ConfirmationModal from '../Shared/ConfirmationModal/ConfirmationModal';
import Tags from '../Tags/Tags';
import TagModifier from '../Tags/TagModifier';
import usePrevious from '../../hooks/usePrevious';

const confirmationModalRef = React.createRef();

const Event = (props) => {
  const [banner, setBanner] = useState([]);
  const [showEventEdit, setShowEventEdit] = useState(false);
  const [showRecurring, setShowRecurring] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isRecurring, setIsRecurring] = useState(false);
  const [lastPage, setLastPage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [participations, setParticipations] = useState([]);
  const [navigateBack, setNavigateBack] = useState(true);
  const [showRecurringEventDeleteConfirmation, setShowRecurringEventDeleteConfirmation] = useState(false);
  const [event, setEvent] = useState({
    participations: []
  });
  const [events, setEvents] = useState([]);

  const temp = useRef();

  const [toastActive, setToastActive] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={2000} />
  ) : null;

  const [selectedTags, setSelectedTags] = useState([]);
  const [initialTags, setInitialTags] = useState([]);
  const [initTags, setInitTags] = useState(false);
  const previousTags = usePrevious(selectedTags);

  const updateTags = useCallback(() => {
    setInitTags(false);

    const params = {
      meta_event: {
        tags_attributes: TagModifier(selectedTags, initialTags)
      }
    };

    axios.patch(`/v1/events/${event.id}/update_tags`, params)
      .then((response) => {
        setSelectedTags(response.data.selectedTags);
        setInitialTags(response.data.selectedTags);
        setInitTags(true);
      })
      .catch(() => {
      });
  }, [event.id, selectedTags, initialTags]);

  useEffect(() => {
    if (initTags && JSON.stringify(previousTags) !== JSON.stringify(selectedTags)) {
      updateTags();
    }
  // eslint-disable-next-line
  }, [selectedTags]);

  const { t } = useTranslation();
  const history = useNavigate();
  const matchParams = useParams();
  const location = useLocation();

  const [tableFilters, setTableFilters] = useState(stateConverter(location));
  const debouncedSearch = useDebounce(tableFilters, 400);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const calendar = query.get('calendar');

    if (calendar) setNavigateBack(false);
    // eslint-disable-next-line
  }, []);

  const descriptionList = () => {
    const items = [
      {
        term: t('shared.title'),
        description: event.title
      },
      {
        term: t('shared.start'),
        description: moment(event.start).format('LLLL')
      },
      {
        term: t('shared.end'),
        description: moment(event.end).format('LLLL')
      }
    ];

    if (event.participation_limit) {
      items.push({
        term: t('shared.participation_limit'),
        description: event.participation_limit
      });
    }

    if (event.pretty_price && props.hasInvoiceAccess) {
      items.push({
        term: t('shared.price'),
        description: event.pretty_price
      });
    }

    if (event.description) {
      items.push({
        term: t('shared.description'),
        description: event.description
      });
    }

    if (event.location) {
      items.push({
        term: t('address.location'),
        description: event.location
      });
    }

    if (event.conferenceUrl) {
      items.push({
        term: 'Online URL',
        description: event.conferenceUrl
      });
    }

    return items;
  };

  const fetchEvent = useCallback(() => {
    axios.get(`/v1/events/${matchParams.id}`)
      .then((response) => {
        temp.current = response.data.event;
        setEvent(response.data.event);
        setIsRecurring(response.data.event.recurring);
        setParticipations(response.data.event.participations);
        setLoading(false);
        setSelectedTags(response.data.selectedTags);
        setInitTags(true);
      })
      .catch(() => {});
  }, [matchParams.id]);

  const fetchRecurringEvents = useCallback(() => {
    setLoading(true);

    const params = {
      page: tableFilters.page
    };

    axios.post(`/v1/events/${matchParams.id}/show_recurring`, params)
      .then((response) => {
        const e = response.data.events.find((ev) => ev.id == temp.current.id);
        if (e && e.participations?.length) {
          setParticipations(e.participations);
        }
        setEvents(response.data.events);
        setLastPage(response.data.lastPage);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  // eslint-disable-next-line
  }, [debouncedSearch]);

  useEffect(() => {
    setLoading(true);
    fetchEvent();
  }, [fetchEvent]);

  useEffect(() => {
    if (showRecurring) {
      fetchRecurringEvents();
    }
  }, [showRecurring, fetchRecurringEvents]);

  const updateEvent = async (e) => {
    setLoading(true);

    if (!e.dig('meta', 'show_title')) {
      e.title = '';
    }

    if (e.signedInParticipations?.length) {
      // eslint-disable-next-line
      e.notify = await confirmationModalRef.current.open({
        title: t('notifications.notify_participants'),
        description: t('notifications.send_email_notification'),
        cancelButton: t('shared.no'),
        submitButton: t('notifications.notify')
      });
    }

    axios.patch(`/v1/events/${event.id}`, e)
      .then((response) => {
        toggleToastActive(t('shared.saved'));
        setEvent(response.data);
        setParticipations(response.data.participations);
        setShowRecurring(false);
        setLoading(false);
      })
      .catch(() => {
        toggleToastActive(t('shared.unable_to_save'));
        setLoading(false);
      });
  };

  const handleDeleteEvent = async (type) => {
    let notify = false;

    notify = await confirmationModalRef.current.open({
      title: t('notifications.notify_participants'),
      description: t('notifications.send_email_notification'),
      cancelButton: t('shared.no'),
      submitButton: t('notifications.notify')
    });

    if (type === 'one') {
      axios.delete(`/v1/events/${event.id}`, {
        data: {
          notify
        }
      })
        .then(() => {
          setShowRecurringEventDeleteConfirmation(false);
          toggleToastActive(t('shared.deleted'));
          setLoading(false);
          history('/calendar');
        })
        .catch(() => {
          setShowRecurringEventDeleteConfirmation(false);
          toggleToastActive(t('shared.unable_to_save'));
          setLoading(false);
        });
    }

    if (type === 'all') {
      const params = {
        notify
      };

      axios.post(`/v1/events/${event.id}/delete_all`, params)
        .then(() => {
          setShowRecurringEventDeleteConfirmation(false);
          toggleToastActive(t('shared.deleted'));
          setLoading(false);
        })
        .catch(() => {
          setShowRecurringEventDeleteConfirmation(false);
          toggleToastActive(t('shared.unable_to_save'));
          setLoading(false);
        });
    }
  };

  const handleDeleteAll = () => {
    handleDeleteEvent('all');
    setShowRecurringEventDeleteConfirmation(false);
  };

  const recurringEventDeleteConfirmation = showRecurringEventDeleteConfirmation ? (
    <RecurringEventDeleteConfirmation
      active={showRecurringEventDeleteConfirmation}
      close={() => setShowRecurringEventDeleteConfirmation(false)}
      handleChangeDeleteAll={() => handleDeleteAll()}
      handleChangeDeleteOnlyThisOne={() => handleDeleteEvent('one')}
    />
  ) : null;

  const handleDelete = () => {
    if (event.meta) {
      setShowRecurringEventDeleteConfirmation(true);
    } else {
      handleDeleteEvent('one');
    }
  };

  const eventEditModal = showEventEdit
    ? (
      <EventEdit
        hideRecurringOptions
        hideSettings
        hideParticipation
        active={showEventEdit}
        close={() => setShowEventEdit(false)}
        handleSubmit={updateEvent}
        handleDelete={handleDelete}
        event={event}
      />
    ) : null;

  const resourceName = {
    singular: t('events.event'),
    plural: t('calendar.events')
  };

  const renderItem = (item) => {
    const { id, startTime } = item;
    const media = <Avatar size="medium" />;

    return (
      <ResourceItem
        id={id}
        showHeader
        accessibilityLabel={`View details for ${item.name}`}
        onClick={() => history(`/events/${id}`)}
        media={media}
      >
        <Stack>
          <div>
            <TextStyle variation="strong">{item.title}</TextStyle>
            {matchParams.id == id && <Badge status="success">{t('shared.selected')}</Badge>}
            <br />
            <TextStyle variation="subdued">{moment(startTime).format('LLLL')}</TextStyle>
          </div>
        </Stack>
      </ResourceItem>
    );
  };

  const makeEventBookable = () => {
    if (!window.confirm(t('event.bookable_product_on_your_booking_page'))) {
      return;
    }

    axios.post('/v1/products/create_recurring_product', { event_token: event.id })
      .then(() => {
        toggleToastActive(t('shared.success'));
        fetchEvent();
      })
      .catch((error) => {
        if (error.response.data.errors) {
          toggleToastActive(`${t('shared.something_went_wrong')} - ${error.response.data.errors.joins(' - ')}`);
        } else {
          toggleToastActive(error.response.data.error);
        }
      });
  };

  const pageActions = () => {
    const actions = [{
      icon: ChecklistMajor,
      content: t('shared.manage_participations'),
      onAction: () => history(`/events/${matchParams.id}/participation_management`)
    }, {
      icon: BlockMinor,
      content: t('event.make_event_bookable'),
      onAction: () => makeEventBookable()
    },
    ...[event?.product_token ? {
      icon: ProductsMinor,
      content: 'Product',
      onAction: () => history(`/products/${event.product_token}`) } : {}]
    ];

    return actions.filter((action) => action.content);
  };

  return (
    <Page
      title={t('events.event')}
      breadcrumbs={[
        {
          content: t('shared.back'),
          onAction: () => (navigateBack ? history(-1) : history('/calendar'))
        }
      ]}
      fullWidth
      separator
      secondaryActions={pageActions()}
    >
      <Layout>
        {toastMarkup}
        {recurringEventDeleteConfirmation}
        {eventEditModal}
        <ModalService ref={confirmationModalRef}>
          <ConfirmationModal />
        </ModalService>
        <Banners banners={banner} onDismissBanner={() => setBanner([])} />
        <Layout.Section>
          <Card
            sectioned
            title={t('shared.details')}
            actions={[
              { content: t('shared.edit'), onAction: () => setShowEventEdit(true) }
            ]}
          >
            <DescriptionList items={descriptionList()} />
          </Card>
          {event.hasProductBookings ? (
            <Card
              sectioned
              title={t('shared.bookings')}
            >
              {event.id && <ProductBookings event_id={event.id} />}
            </Card>
          ) : ''}
          {!showRecurring && isRecurring && (
            <div className={classes.Navigation}>
              <Button loading={loading} onClick={() => setShowRecurring(true)}>{t('events.load_connected_events')}</Button>
            </div>
          )}
          {showRecurring && !loading && (
            <Card sectioned>
              <ResourceList
                resourceName={resourceName}
                items={events}
                renderItem={renderItem}
                selectedItems={selectedItems}
                onSelectionChange={setSelectedItems}
              />
              <TablePagination
                resetOnRefresh
                pageFilter={tableFilters.page}
                setTableFilters={setTableFilters}
                records={events}
                lastPage={lastPage}
                length={5}
              />
            </Card>
          )}
        </Layout.Section>
        <Layout.Section secondary>
          <Card sectioned>
            <Tags
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
              tagUrl="/v1/events/tags"
            />
          </Card>
          <Card
            sectioned
            title={t('shared.participants')}
          >
            {participations?.length && !loading ? <ParticipantsResourceList data={participations} t={t} /> : null}
          </Card>
          <Comments
            commentableId={event.id}
            commentablePath="events"
          />
        </Layout.Section>
      </Layout>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  hasInvoiceAccess: state.auth.hasInvoiceAccess
});

export default connect(mapStateToProps)(Event);
