import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Select from 'react-select';
import axios from 'axios';
import { Label, Stack, Tag } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import useDebounce from '../../hooks/useDebounce';
import NotificationBanner from '../Shared/NotificationBanner/NotificationBanner';

const ShareModal = (props) => {
  const buttonRef = useRef(null);
  const [users, setUsers] = useState([]);
  const [selected, setSelected] = useState({});
  const [sharedWith, setSharedwith] = useState([]);
  const [inputValue, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState('');

  const debouncedSearch = useDebounce(inputValue, 400);
  const { t } = useTranslation();

  const fetchDefault = () => {
    const params = {
      per_page: 30,
      search: inputValue,
      q: '',
      s: ''
    };

    axios.post(`/v1/surveys/${props.data?.token}/share_users`, params)
      .then((res) => {
        setUsers(res.data.permissions);
      })
      .catch(() => {
      })
      .then(() => setLoading(false));
  };

  const handleNotification = (value) => {
    setNotification(value.target.value);
  };

  useEffect(() => {
    if (selected?.value) {
      const params = {
        user_token: selected.value
      };

      axios.post(`/v1/surveys/${props.data?.token}/share`, params)
        .then(() => {
          setSelected({});
          fetchAssignments();
          fetchDefault();
        })
        .catch(() => {});
    }
  // eslint-disable-next-line
  }, [selected]);

  const fetchAssignments = () => {
    axios.get(`/v1/surveys/${props.data?.token}/permissions`)
      .then((response) => {
        setSharedwith(response.data.users);
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (props.data?.token) {
      fetchDefault();
    }
  // eslint-disable-next-line
  }, [debouncedSearch]);

  useEffect(() => {
    if (props.data?.token) {
      fetchDefault();
      fetchAssignments();
    }
  // eslint-disable-next-line
  }, [props.data?.token, props.timestamp]);

  const handleChange = (value) => {
    if (value) {
      setLoading(true);
    }

    setValue(value);
  };

  const removeTag = (tag) => () => {
    setSharedwith((previousTags) => previousTags.filter((previousTag) => previousTag.value !== tag.value));

    axios.delete(`/v1/surveys/${props.data?.token}/remove_share?survey_assigment_id=${tag.id}`)
      .then(() => {})
      .catch(() => {});
  };

  const cleanup = (resolve) => {
    resolve(notification);

    setSelected({});
    setSharedwith([]);
    setLoading(false);
  };

  const tagMarkup = sharedWith.map((option) => (
    <Tag key={option.value} onRemove={removeTag(option)}>
      {option.label}
    </Tag>
  ));

  return (
    <Transition.Root show={props.show} as={Fragment}>
      <Dialog as="div" className="fixed z-20 inset-0 overflow-y-auto" initialFocus={buttonRef} onClose={() => {}}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative space-y-2 inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-6xl sm:p-6">
              <h5 className="font-medium">{props.title}</h5>
              <Stack spacing="tight">{tagMarkup}</Stack>
              <div className="pb-4 mb-4 react-select">
                <Select
                  // cacheOptions
                  inputValue={inputValue}
                  isClearable
                  onInputChange={handleChange}
                  value={selected}
                  onChange={setSelected}
                  // noOptionsMessage="Loading"
                  isLoading={loading}
                  defaultOptions={users}
                  options={users}
                  getOptionValue={(user) => user.value}
                  getOptionLabel={(user) => user.label}
                />
                <div className="mt-6">
                  <Label>{t('notifications.message')}</Label>
                  <textarea
                    id="notification"
                    rows={4}
                    name="description"
                    value={notification}
                    className="p-4 mt-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 w-full sm:text-sm border border-gray-300 rounded-md"
                    onChange={handleNotification}
                  />
                </div>
              </div>
              <NotificationBanner label={t('survey.notification_message')} color="bg-blue-400 mb-4 pb-4" />
              <div className="pt-4 space-z-4 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                  onClick={() => cleanup(props.onOk)}
                  ref={buttonRef}
                >
                  {props.submitButton}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-red-400 text-base font-medium text-gray-700 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={() => cleanup(props.onCancel)}
                >
                  {props.cancelButton}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ShareModal;
