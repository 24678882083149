/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Avatar, Card, DropZone, Layout, Page, Stack, Tabs, TextField,
  Toast, ContextualSaveBar, Button, FormLayout, Banner, TextStyle, Select
} from '@shopify/polaris';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DeleteMajor, HideMinor, ViewMinor } from '@shopify/polaris-icons';
import { stateConverter } from '../FilterEngine/filterParams';
import { getTabIndex, getTabId, onTabSelect } from '../FilterEngine/Tabs/tabs';
import Banners from '../Shared/Banners';
import TinymceEditor from '../Tinymce/TinymceEditor';
import * as actions from '../../store/actions/index';
import SwitchToggle from '../Shared/SwitchToggle';
import PageBuilder from './PageBuilder';
import StaffSectionBlocks from './StaffSectionBlocks';
import ProductAssignment from './ProductAssignment';
import InstructorAssignment from './InstructorAssignment';
import ClientAssignment from './ClientAssignment';
import SignupFields from './SignupFields';

const PublicProfileEdit = (props) => {
  const [uniquenessError, setUniquenessError] = useState('');
  const [showSaveBar, setShowSaveBar] = useState(false);
  const [publicProfile, setPublicProfile] = useState({});
  const [loading, setLoading] = useState(true);
  const [banner, setBanner] = useState([]);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [staffBackgroundImage, setStaffBackgroundImage] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [urlName, setUrlName] = useState('');
  const [faqs, setFaqs] = useState([]);
  const [pages, setPages] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [signupFields, setSignupFields] = useState([]);
  const [instructorSignupFields, setInstructorSignupFields] = useState([]);
  const [customFieldError, setCustomFieldError] = useState(false);
  const [signupFieldError, setSignupFieldError] = useState(false);
  const [customFieldLang, setCustomFieldLang] = useState('en');

  const { t } = useTranslation();
  const location = useLocation();
  const history = useNavigate();

  const queryParams = new URLSearchParams(location.search);

  const [toastMessage, setToastMessage] = useState('');
  const [toastActive, setToastActive] = useState(false);
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={2000} />
  ) : null;

  const [tableFilters, setTableFilters] = useState(stateConverter(location));

  const handleChange = (field) => (value) => {
    setFormErrors({ ...formErrors, [field]: null });
    setPublicProfile({ ...publicProfile, [field]: value });
    setShowSaveBar(true);
  };

  const handleFaqChange = (field, i) => (value) => {
    const temp = [...faqs];
    temp[i][field] = value;
    setFaqs(temp);
    setShowSaveBar(true);
  };

  const addFaq = () => {
    const temp = [...faqs];
    temp.push({});
    setFaqs(temp);
    setShowSaveBar(true);
  };

  const removeFaq = (i) => {
    const temp = [...faqs];
    temp[i]._destroy = '1';
    setFaqs(temp);
    setShowSaveBar(true);
  };

  const displayFaqs = () => faqs.map((faq, i) => (
    !faq._destroy ? (
      <Card.Section key={i}>
        <FormLayout>
          <TextField
            value={faq.question}
            onChange={handleFaqChange('question', i)}
            label={t('public_profile.faq.question')}
          />
          <TextField
            value={faq.answer}
            onChange={handleFaqChange('answer', i)}
            label={t('public_profile.faq.answer')}
          />
          <Button destructive icon={DeleteMajor} onClick={() => removeFaq(i)}>{t('shared.delete')}</Button>
        </FormLayout>
      </Card.Section>
    ) : null));

  const tabs = [
    {
      id: 'General',
      content: t('navigation.general'),
      accessibilityLabel: t('navigation.general'),
      panelID: 'General'
    },
    {
      id: 'Pages',
      content: t('bookingportal.pages'),
      accessibilityLabel: 'Pages',
      panelID: 'Pages'
    },
    {
      id: 'FAQ',
      content: t('public_profile.faq.name'),
      accessibilityLabel: 'FAQ',
      panelID: 'FAQ'
    },
    {
      id: 'advanced',
      content: t('product.advanced_settings'),
      accessibilityLabel: 'advanced',
      panelID: 'advanced'
    },
    {
      id: 'productAssignment',
      content: t('bookingportal.product_assignment'),
      accessibilityLabel: 'productAssignment',
      panelID: 'productAssignment'
    }, {
      id: 'instructorAssignment',
      content: t('bookingportal.instructor_assignment'),
      accessibilityLabel: 'instructorAssignment',
      panelID: 'instructorAssignment'
    }, {
      id: 'clientAssignment',
      content: t('bookingportal.client_assignment'),
      accessibilityLabel: 'clientAssignment',
      panelID: 'clientAssignment'
    }, {
      id: 'SignupTemplate',
      content: t('bookingportal.signup_template'),
      accessibilityLabel: 'Signup Template',
      panelID: 'SignupTemplate'
    }
  ];

  if (publicProfile?.program_space === 'default') {
    tabs.push({
      id: 'StaffProfileTemplate',
      content: t('bookingportal.staff_profile_template'),
      accessibilityLabel: 'StaffProfileTemplate',
      panelID: 'StaffProfileTemplate'
    });
  }

  const getPages = () => {
    const data = [{
      id: 'home',
      content: t('navigation.home'),
      accessibilityLabel: 'Home',
      disabled: true,
      panelID: 'home',
      data: publicProfile.data
    }];

    if (pages?.length) {
      for (let i = 0; i < pages.length; i++) {
        if (pages[i].id === 'home') {
          const index = data.findIndex((entry) => entry.id === 'home');
          data[index] = pages[i];
          // eslint-disable-next-line
          continue;
        } else if (pages[i].id === 'faq' && !faqs.length) {
          const index = data.findIndex((entry) => entry.id === 'faq');
          data[index] = pages[i];
          // eslint-disable-next-line
          continue;
        }

        data.push(pages[i]);

        if (i + 1 === pages.length && publicProfile?.show_bookings && !data.some((entry) => entry.id === 'booking')) {
          data.push({
            id: 'booking',
            content: t('public_profile.booking'),
            accessibilityLabel: 'booking',
            panelID: 'booking'
          });
        }

        if (i + 1 === pages.length && faqs.length && !data.some((entry) => entry.id === 'faq')) {
          data.push({
            id: 'faq',
            content: t('public_profile.faq.name'),
            accessibilityLabel: 'faq',
            panelID: 'faq',
            disabled: true
          });
        }
      }
    } else {
      if (publicProfile?.show_bookings) {
        data.push({
          id: 'booking',
          content: t('public_profile.booking'),
          accessibilityLabel: 'booking',
          panelID: 'booking'
        });
      }

      if (faqs.length) {
        data.push({
          id: 'faq',
          content: t('public_profile.faq.name'),
          accessibilityLabel: 'faq',
          panelID: 'faq',
          disabled: true
        });
      }
    }

    return data;
  };

  const fetchProfile = useCallback(() => {
    const programSpace = queryParams.get('program_space') ?? '';

    axios.get(`/v1/public_profiles/profile?program_space=${programSpace}`)
      .then((response) => {
        setPublicProfile(response.data);
        setPages(response.data.pages);
        if (response.data.backgroundImageUrl) setBackgroundImage(new File([''], response.data.backgroundImageUrl));
        if (response.data.staffBackgroundImageUrl) setStaffBackgroundImage(new File([''], response.data.staffBackgroundImageUrl));
        if (response.data.avatarUrl) setAvatar(new File([''], response.data.avatarUrl));
        setFaqs(response.data.faqs);
        setCustomFields(response.data.custom_fields);
        setSignupFields(response.data.signup_fields);
        setInstructorSignupFields(response.data.instructor_signup_fields);
        setUrlName(response.data.url_name);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  const handlePageChange = (res) => {
    setPages(res);
    setShowSaveBar(true);
  };

  const handleStaffBackgroundImageDropZoneDrop = useCallback((_dropFiles, acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length) {
      setBanner([{ title: t('shared.upload_avatar_validation'), status: 'critical' }]);
      setTimeout(() => {
        setBanner([]);
      }, 3000);
    } else {
      setPublicProfile({ ...publicProfile, staffBackgroundImageUrl: window.URL.createObjectURL(acceptedFiles[0]) });
      setStaffBackgroundImage(acceptedFiles[0]);
      setShowSaveBar(true);
    }
  }, [t, publicProfile]);

  const handleBackgroundImageDropZoneDrop = useCallback((_dropFiles, acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length) {
      setBanner([{ title: t('shared.upload_avatar_validation'), status: 'critical' }]);
      setTimeout(() => {
        setBanner([]);
      }, 3000);
    } else {
      setPublicProfile({ ...publicProfile, backgroundImageUrl: window.URL.createObjectURL(acceptedFiles[0]) });
      setBackgroundImage(acceptedFiles[0]);
      setShowSaveBar(true);
    }
  }, [t, publicProfile]);

  const handleAvatarDropZoneDrop = useCallback((_dropFiles, acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length) {
      setBanner([{ title: t('shared.upload_avatar_validation'), status: 'critical' }]);
      setTimeout(() => {
        setBanner([]);
      }, 3000);
    } else {
      setPublicProfile({ ...publicProfile, avatarUrl: window.URL.createObjectURL(acceptedFiles[0]) });
      setAvatar(acceptedFiles[0]);
      setShowSaveBar(true);
    }
  }, [t, publicProfile]);

  const resetBackgroundImage = () => {
    setBackgroundImage(null);
    setShowSaveBar(true);
  };

  const resetStaffBackgroundImage = () => {
    setStaffBackgroundImage(null);
    setShowSaveBar(true);
  };

  const resetAvatar = () => {
    setAvatar(null);
    setShowSaveBar(true);
  };

  const uploadedStaffBackgroundImageFile = staffBackgroundImage && (
    <Stack alignment="center">
      <img src={publicProfile.staffBackgroundImageUrl} alt="background" width="100%" />
      {/* <Thumbnail size="large" name="invoice_logo" source={backgroundImage} /> */}
    </Stack>
  );

  const uploadedBackgroundImageFile = backgroundImage && (
    <Stack alignment="center">
      <img src={publicProfile.backgroundImageUrl} alt="background" width="100%" />
      {/* <Thumbnail size="large" name="invoice_logo" source={backgroundImage} /> */}
    </Stack>
  );

  const uploadedAvatarFile = avatar && (
    <Stack alignment="center">
      <Avatar size="large" source={publicProfile.avatarUrl} />
      {/* <Thumbnail size="large" name="invoice_logo" source={backgroundImage} /> */}
    </Stack>
  );

  const formValid = () => {
    const e = {};
    if (!urlName) {
      e.urlName = t('shared.is_required');
    }

    if (!publicProfile.name) {
      e.name = t('shared.is_required');
    }

    setFormErrors(e);
    return Object.entries(e).length === 0;
  };

  const onSubmit = () => {
    setFormErrors({});

    if (!formValid()) return;
    const formData = new FormData();

    if (publicProfile.data) {
      formData.append('public_profile[data]', JSON.stringify(publicProfile.data));
    } else {
      formData.append('public_profile[data]', '');
    }

    if (publicProfile.footer) {
      formData.append('public_profile[footer]', publicProfile.footer);
    } else {
      formData.append('public_profile[footer]', '');
    }

    formData.append('public_profile[url_name]', urlName);
    formData.append('public_profile[name]', publicProfile.name);
    formData.append('public_profile[show_bookings]', publicProfile.show_bookings);
    formData.append('public_profile[background_positioning]', publicProfile.background_positioning);
    formData.append('public_profile[staff_background_height]', publicProfile.staff_background_height);
    formData.append('public_profile[full_width_tabs]', publicProfile.full_width_tabs);
    formData.append('public_profile[description]', publicProfile.description || '');
    formData.append('public_profile[default_style]', publicProfile.default_style);
    formData.append('public_profile[book_individuals]', publicProfile.book_individuals);
    formData.append('public_profile[require_login]', publicProfile.require_login);
    formData.append('public_profile[visibility_type]', publicProfile.visibility_type);

    if (faqs.length) {
      faqs.forEach((faq, i) => {
        if (faq.id) formData.append(`public_profile[faqs_attributes][${i}][id]`, faq.id);
        formData.append(`public_profile[faqs_attributes][${i}][question]`, faq.question);
        formData.append(`public_profile[faqs_attributes][${i}][answer]`, faq.answer);

        if (faq._destroy) {
          formData.append(`public_profile[faqs_attributes][${i}][_destroy]`, faq._destroy);
        }
      });
    }

    if (staffBackgroundImage?.size > 0) {
      formData.append('public_profile[staff_background_image]', staffBackgroundImage);
    }

    if (backgroundImage?.size > 0) {
      formData.append('public_profile[background_image]', backgroundImage);
    }

    if (!backgroundImage) {
      formData.append('remove_background_image', true);
    }

    if (!staffBackgroundImage) {
      formData.append('remove_staff_background_image', true);
    }

    if (avatar?.size > 0) {
      formData.append('public_profile[avatar]', avatar);
    }

    if (!avatar) {
      formData.append('remove_avatar', true);
    }

    if (customFields.length) {
      customFields.forEach((customField, i) => {
        if (customField.id) formData.append(`public_profile[profile_template_fields_attributes][${i}][id]`, customField.id);
        formData.append(`public_profile[profile_template_fields_attributes][${i}][label]`, customField.label);
        formData.append(`public_profile[profile_template_fields_attributes][${i}][value]`, customField.value);
        // formData.append(`public_profile[profile_template_fields_attributes][${i}][category]`, customField.category);
        // formData.append(`public_profile[profile_template_fields_attributes][${i}][required]`, customField.required);
        formData.append(`public_profile[profile_template_fields_attributes][${i}][order_number]`, customField.order_number);
        formData.append(`public_profile[profile_template_fields_attributes][${i}][language]`, customField.language);
        if (customField._destroy) {
          formData.append(`public_profile[profile_template_fields_attributes][${i}][_destroy]`, customField._destroy);
        }
      });
    }

    if (signupFields.length) {
      signupFields.forEach((customField, i) => {
        if (customField.id) formData.append(`public_profile[signup_fields_attributes][${i}][id]`, customField.id);
        formData.append(`public_profile[signup_fields_attributes][${i}][label]`, customField.label);
        formData.append(`public_profile[signup_fields_attributes][${i}][field_type]`, 'SignupField');
        // formData.append(`public_profile[signup_fields_attributes][${i}][value]`, customField.value);
        formData.append(`public_profile[signup_fields_attributes][${i}][category]`, customField.category);
        formData.append(`public_profile[signup_fields_attributes][${i}][required]`, customField.required);
        formData.append(`public_profile[signup_fields_attributes][${i}][order_number]`, customField.order_number);
        formData.append(`public_profile[signup_fields_attributes][${i}][language]`, customField.language);
        if (customField._destroy) {
          formData.append(`public_profile[signup_fields_attributes][${i}][_destroy]`, customField._destroy);
        }
      });
    }

    if (instructorSignupFields.length) {
      instructorSignupFields.forEach((customField, i) => {
        if (customField.id) formData.append(`public_profile[instructor_signup_fields_attributes][${i}][id]`, customField.id);
        formData.append(`public_profile[instructor_signup_fields_attributes][${i}][label]`, customField.label);
        formData.append(`public_profile[instructor_signup_fields_attributes][${i}][field_type]`, 'InstructorSignupField');
        // formData.append(`public_profile[instructor_signup_fields_attributes][${i}][value]`, customField.value);
        formData.append(`public_profile[instructor_signup_fields_attributes][${i}][category]`, customField.category);
        formData.append(`public_profile[instructor_signup_fields_attributes][${i}][required]`, customField.required);
        formData.append(`public_profile[instructor_signup_fields_attributes][${i}][order_number]`, customField.order_number);
        formData.append(`public_profile[instructor_signup_fields_attributes][${i}][language]`, customField.language);
        if (customField._destroy) {
          formData.append(`public_profile[instructor_signup_fields_attributes][${i}][_destroy]`, customField._destroy);
        }
      });
    }

    setUniquenessError('');

    if (publicProfile.id) {
      const programSpace = queryParams.get('program_space') ?? '';
      axios.patch(`/v1/public_profiles/${urlName}?program_space=${programSpace}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          setPublicProfile(response.data);
          setFaqs(response.data.faqs);
          setUrlName(response.data.url_name);
          if (response.data.program_space === 'default') {
            props.updateUrlName(response.data.url_name);
          }
          setSignupFields(response.data.signup_fields);
          setInstructorSignupFields(response.data.instructor_signup_fields);
          toggleToastActive(t('shared.saved'));
        })
        .catch((error) => {
          if (error.response.data?.errors?.includes('UniqueViolation')) {
            setUniquenessError(t('public_profile.taken_link'));
          }
          toggleToastActive(t('shared.unable_to_save'));
        })
        .then(() => {
          axios.patch(`/v1/public_profiles/${urlName}/update_pages?program_space=${programSpace}`, { public_profile: { pages: getPages() } })
            .then(() => {})
            .catch(() => {
              toggleToastActive(t('shared.unable_to_save'));
            });
          setShowSaveBar(false);
        });
    } else {
      axios.post('/v1/public_profiles/', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          setPublicProfile(response.data);
          setFaqs(response.data.faqs);
          setUrlName(response.data.url_name);
          if (response.data.program_space === 'default') {
            props.updateUrlName(response.data.url_name);
          }
          toggleToastActive(t('shared.saved'));

          axios.patch(`/v1/public_profiles/${response.data.url_name}/update_pages`, { public_profile: { pages: getPages() } })
            .then(() => {})
            .catch(() => {
              toggleToastActive(t('shared.unable_to_save'));
            });
        })
        .catch((error) => {
          if (error.response.data?.errors?.includes('UniqueViolation')) {
            setUniquenessError(t('public_profile.taken_link'));
          }
          toggleToastActive(t('shared.unable_to_save'));
        })
        .then(() => {
          setShowSaveBar(false);
        });
    }
  };

  const onChangeEvent = (change, e) => {
    change(e?.replace('/', '')?.replace(' ', ''));
    setFormErrors({});
    setShowSaveBar(true);
  };

  const createLink = () => {
    if (!publicProfile.url_name && !urlName) {
      return t('public_profile.portal_link');
    }

    // const programSpace = queryParams.get('program_space') ?? '';
    // const pLink = programSpace ? `?program_space=${programSpace}` : '';

    // const url = `https://${publicProfile.subdomain || 'app'}.planubo.com/booking/${urlName}${pLink}`;

    return <a href={publicProfile.link}>{`${t('public_profile.custom_booking_portal')}: ${publicProfile.link}`}</a>;
  };

  const handleActiveToggle = (id, action) => {
    if (!window.confirm(action === 'inactivate' ? t('client.inactivate_toggle') : t('client.activate_toggle'))) {
      return;
    }

    axios.post(`/v1/public_profiles/${id}/${action}`)
      .then(() => {
        setPublicProfile({ ...publicProfile, accountState: publicProfile.active ? 'inactive' : 'active', active: !publicProfile.active });
        setBanner([{ title: t('shared.success'), status: 'success' }]);
        setTimeout(() => {
          setBanner([]);
        }, [3000]);
      })
      .catch((error) => {
        setBanner([{ title: t('tabs.failure'), status: 'critical', details: error.response.data.errors }]);
      });
  };

  const pageActions = () => {
    if (!publicProfile || loading) {
      return {};
    }

    const toggleContent = publicProfile.active ? 'Inactivate' : 'Activate';
    const content = publicProfile.active ? t('client.inactivate') : t('client.activate');

    const a = {
      icon: publicProfile.active ? HideMinor : ViewMinor,
      content,
      destructive: publicProfile.active,
      onAction: () => handleActiveToggle(publicProfile.token, toggleContent.toLowerCase())
    };

    return a;
  };

  const handleTinymceChange = (field) => (value) => {
    setPublicProfile({ ...publicProfile, [field]: value });
    setShowSaveBar(true);
  };

  const handlePageUpdate = (field, key) => (value) => {
    const temp = getPages();
    const index = temp.findIndex((page) => page.id === field);

    temp[index][key] = value;

    setPages([...temp]);
    setShowSaveBar(true);
  };

  const addCustomField = () => {
    setCustomFields([...customFields, {
      language: customFieldLang,
      order_number: customFields.length
    }]);
  };

  const deleteCustomField = (id) => {
    const temp = [...customFields];
    temp[id]._destroy = '1';
    setCustomFields([...temp]);
    setShowSaveBar(true);
  };

  const handleCustomFieldChange = (idx) => (field) => (value) => {
    const temp = [...customFields];
    temp[idx][field] = value;

    setCustomFields(temp);
    setShowSaveBar(true);
  };

  const handleDragnDropChange = (items) => {
    setCustomFields(items);
    setShowSaveBar(true);
  };

  const addSignupField = () => {
    setSignupFields([...signupFields, {
      language: customFieldLang,
      order_number: signupFields.length
    }]);
  };

  const addInstructorSignupField = () => {
    setInstructorSignupFields([...instructorSignupFields, {
      language: customFieldLang,
      order_number: instructorSignupFields.length
    }]);
  };

  const deleteSignupField = (id) => {
    const temp = [...signupFields];
    temp[id]._destroy = '1';
    setSignupFields([...temp]);
    setShowSaveBar(true);
  };

  const deleteInstructorSignupField = (id) => {
    const temp = [...instructorSignupFields];
    temp[id]._destroy = '1';
    setInstructorSignupFields([...temp]);
    setShowSaveBar(true);
  };

  const handleSignupFieldChange = (idx) => (field) => (value) => {
    const temp = [...signupFields];
    temp[idx][field] = value;

    setSignupFields(temp);
    setShowSaveBar(true);
  };

  const handleInstructorSignupFieldChange = (idx) => (field) => (value) => {
    const temp = [...instructorSignupFields];
    temp[idx][field] = value;

    setInstructorSignupFields(temp);
    setShowSaveBar(true);
  };

  const handleDragnDropChangeSignupFieldChange = (items) => {
    setSignupFields(items);
    setShowSaveBar(true);
  };

  const handleDragnDropChangeInstructorSignupFieldChange = (items) => {
    setInstructorSignupFields(items);
    setShowSaveBar(true);
  };

  return (
    <Page
      separator
      title={t('public_profile.portal')}
      fullWidth
      primaryAction={publicProfile?.program_space !== 'default' && pageActions()}
    >
      {showSaveBar && (
        <ContextualSaveBar
          message={t('shared.unsaved_changes')}
          saveAction={{
            onAction: () => onSubmit(),
            loading,
            disabled: false
          }}
          discardAction={{
            onAction: () => window.location.reload()
          }}
        />
      )}
      {toastMarkup}
      <Tabs
        tabs={tabs}
        selected={getTabIndex(tabs, tableFilters.tab)}
        onSelect={(tabIndex) => onTabSelect({ history, location }, tabs, tabIndex, setTableFilters)}
      >
        <br />
        {getTabId(tabs, tableFilters.tab) === 'General' && (
          <Layout>
            <Layout.Section secondary>
              <Banners banners={banner} onDismissBanner={() => setBanner([])} />
              <Card sectioned>
                <Card.Section title={t('public_profile.booking_link')}>
                  {uniquenessError && (
                    <Banner status="critical">
                      <p>{uniquenessError}</p>
                    </Banner>
                  )}
                  <TextField
                    requiredIndicator
                    value={urlName}
                    onChange={(e) => onChangeEvent(setUrlName, e)}
                    type="text"
                    error={formErrors.urlName}
                    helpText={createLink()}
                  />
                </Card.Section>
                <Card.Section title={t('public_profile.background_image')} actions={[{ content: t('shared.change'), onAction: () => resetBackgroundImage() }]}>
                  {!backgroundImage ? (
                    <DropZone type="image" accept="image/*" allowMultiple={false} onDrop={handleBackgroundImageDropZoneDrop}>
                      <DropZone.FileUpload />
                    </DropZone>
                  ) : uploadedBackgroundImageFile}
                  {backgroundImage && (
                    <TextField
                      value={publicProfile.background_positioning}
                      onChange={handleChange('background_positioning')}
                      type="text"
                      helpText={t('bookingportal.custom_picture_positioning')}
                    />
                  )}
                </Card.Section>
                <Card.Section title={t('shared.avatar')} actions={[{ content: t('shared.change'), onAction: () => resetAvatar() }]}>
                  {!avatar ? (
                    <DropZone type="image" accept="image/*" allowMultiple={false} onDrop={handleAvatarDropZoneDrop}>
                      <DropZone.FileUpload />
                    </DropZone>
                  ) : uploadedAvatarFile}
                </Card.Section>
                <Card.Section title={t('navigation.profile')}>
                  <Stack>
                    <Stack.Item fill>
                      <TextField
                        value={publicProfile.name}
                        onChange={handleChange('name')}
                        type="text"
                        error={formErrors.name}
                        helpText={t('public_profile.name_help')}
                      />
                    </Stack.Item>
                    <Stack.Item>
                      <TextField
                        value={publicProfile.description}
                        onChange={handleChange('description')}
                        type="text"
                        placeholder={t('shared.optional')}
                        error={formErrors.description}
                        helpText={t('public_profile.about_you_desc')}
                      />
                    </Stack.Item>
                  </Stack>
                </Card.Section>
                <Card.Section title={t('bookingportal.website_settings')}>
                  <Select
                    label={t('bookingportal.default_style')}
                    options={[{ label: t('bookingportal.all_pages'), value: 'default' }, { label: t('bookingportal.only_on_home_tab'), value: 'home' }, { label: t('bookingportal.hide'), value: 'none' }]}
                    onChange={handleChange('default_style')}
                    value={publicProfile.default_style}
                  />
                  <TextStyle variation="subdued">{t('bookingportal.where_to_show_background_image')}</TextStyle>
                </Card.Section>
                <Card.Section>
                  <Stack alignment="center">
                    <Stack.Item fill>
                      <TextStyle>{t('public_profile.show_booking_tabs')}</TextStyle>
                    </Stack.Item>
                    <SwitchToggle
                      handleChange={handleChange('show_bookings')}
                      checked={publicProfile.show_bookings}
                    />
                  </Stack>
                  <TextStyle variation="subdued">{t('public_profile.show_booking_tabs_desc')}</TextStyle>
                </Card.Section>
                <Card.Section>
                  <Stack alignment="center">
                    <Stack.Item fill>
                      <TextStyle>{t('public_profile.tab_width')}</TextStyle>
                    </Stack.Item>
                    <SwitchToggle
                      handleChange={handleChange('full_width_tabs')}
                      checked={publicProfile.full_width_tabs}
                    />
                  </Stack>
                  <TextStyle variation="subdued">{t('public_profile.tab_width_desc')}</TextStyle>
                </Card.Section>
                <Card.Section>
                  <Stack alignment="center">
                    <Stack.Item fill>
                      <TextStyle>{t('bookingportal.book_individuals')}</TextStyle>
                    </Stack.Item>
                    <SwitchToggle
                      handleChange={handleChange('book_individuals')}
                      checked={publicProfile.book_individuals}
                    />
                  </Stack>
                  <TextStyle variation="subdued">{t('bookingportal.setting_book_product_or_staff_first')}</TextStyle>
                </Card.Section>
                <Card.Section>
                  <Stack alignment="center">
                    <Stack.Item fill>
                      <TextStyle>{t('bookingportal.require_login')}</TextStyle>
                    </Stack.Item>
                    <SwitchToggle
                      handleChange={handleChange('require_login')}
                      checked={publicProfile.require_login}
                    />
                  </Stack>
                  <TextStyle variation="subdued">{t('bookingportal.login_first_before_viewing_booking_portal')}</TextStyle>
                </Card.Section>
              </Card>
            </Layout.Section>
            <Layout.Section>
              {/* <Card>
                <Card.Section title={t('shared.description')}>
                  <Banner status="info">This is deprecated and will be removed soon. Please use the page builder to update your content</Banner>
                  <br />
                  {!loading && (
                    <TinymceEditor
                      value={publicProfile.data}
                      handleChange={handleTinymceChange('data')}
                    />
                  )}
                </Card.Section>
              </Card> */}
              <Card>
                <Card.Section title={t('public_profile.page_footer')}>
                  {!props.isPremiumPlusMember && (
                    <Banner status="info">
                      <p>{t('public_profile.you_must_be_premium_plus_page_footer')}</p>
                    </Banner>
                  )}
                  {!loading && props.isPremiumPlusMember && (
                    <TinymceEditor
                      disabled={!props.isPremiumPlusMember}
                      value={publicProfile.footer}
                      handleChange={handleTinymceChange('footer')}
                    />
                  )}
                </Card.Section>
              </Card>
            </Layout.Section>
          </Layout>
        )}
        {getTabId(tabs, tableFilters.tab) === 'Pages' && (
          !loading && (
            <PageBuilder
              publicProfile={publicProfile}
              pages={getPages()}
              removeBookingPage={handleChange}
              onChange={handlePageChange}
              handlePageUpdate={handlePageUpdate}
            />
          )
        )}
        {getTabId(tabs, tableFilters.tab) === 'FAQ' && (
          <Card sectioned actions={{ content: t('shared.add'), onAction: () => addFaq() }}>
            {displayFaqs()}
          </Card>
        )}
        {getTabId(tabs, tableFilters.tab) === 'productAssignment' && (
          <Card sectioned>
            {!loading && <ProductAssignment publicProfile={publicProfile} />}
          </Card>
        )}
        {getTabId(tabs, tableFilters.tab) === 'instructorAssignment' && (
          <Card sectioned>
            {!loading && <InstructorAssignment publicProfile={publicProfile} />}
          </Card>
        )}
        {getTabId(tabs, tableFilters.tab) === 'clientAssignment' && (
          <Card sectioned>
            {!loading && <ClientAssignment publicProfile={publicProfile} />}
          </Card>
        )}
        {getTabId(tabs, tableFilters.tab) === 'advanced' && (
          <Layout>
            <Layout.AnnotatedSection
              title={t('bookingportal.visibility_type')}
              description={t('bookingportal.choose_which_clients_can_view_this_space')}
            >
              <Card sectioned>
                <Card.Section>
                  <Select
                    options={[{ label: t('bookingportal.all_clients'), value: 'all_clients' }, { label: t('bookingportal.selected_clients_only'), value: 'selected_clients_only' }]}
                    onChange={handleChange('visibility_type')}
                    value={publicProfile.visibility_type}
                  />
                </Card.Section>
              </Card>
            </Layout.AnnotatedSection>
          </Layout>
        )}
        {getTabId(tabs, tableFilters.tab) === 'SignupTemplate' && (
          <Layout>
            <Layout.Section>
              <Layout.AnnotatedSection
                title={t('bookingportal.signup_link_client')}
                description={t('bookingportral.link_for_client_registration_to_program_space')}
              >
                <div className="mr-6 ml-10">
                  <Card sectioned>
                    <Card.Section title={t('bookingportal.with_or_without_validation')}>
                      {`${publicProfile.signup_link}&signupType=client`}
                    </Card.Section>
                    <Card.Section title={t('bookingportal.without_validation')}>
                      {`${publicProfile.signup_link}&signupType=client&signupRole=default`}
                    </Card.Section>
                  </Card>
                </div>
              </Layout.AnnotatedSection>
              <Layout.AnnotatedSection
                title={t('bookingportal.signup_fields_client')}
                description={t('bookingportal.signup_fields_client_description')}
              >
                <Layout.Section>
                  <SignupFields
                    customFields={signupFields}
                    handleCustomFieldChange={handleSignupFieldChange}
                    deleteCustomField={deleteSignupField}
                    addCustomField={addSignupField}
                    setCustomFieldLang={setCustomFieldLang}
                    customFieldLang={customFieldLang}
                    error={signupFieldError}
                    setError={setSignupFieldError}
                    setCustomFields={handleDragnDropChangeSignupFieldChange}
                    title={t('custom_fields.plural')}
                  />
                </Layout.Section>
              </Layout.AnnotatedSection>
              {publicProfile?.program_space === 'default' ? (
                <Layout.AnnotatedSection
                  title={t('bookingportal.signup_fields_instructor')}
                  description={t('bookingportal.signup_fields_instructor_description')}
                >
                  <Layout.Section>
                    <SignupFields
                      customFields={instructorSignupFields}
                      handleCustomFieldChange={handleInstructorSignupFieldChange}
                      deleteCustomField={deleteInstructorSignupField}
                      addCustomField={addInstructorSignupField}
                      setCustomFieldLang={setCustomFieldLang}
                      customFieldLang={customFieldLang}
                      error={signupFieldError}
                      setError={setSignupFieldError}
                      setCustomFields={handleDragnDropChangeInstructorSignupFieldChange}
                      title={t('custom_fields.plural')}
                    />
                  </Layout.Section>
                </Layout.AnnotatedSection>
              ) : ''}
            </Layout.Section>
          </Layout>
        )}
        {getTabId(tabs, tableFilters.tab) === 'StaffProfileTemplate' && (
          <>
            <Card sectioned>
              {!loading && (
                <StaffSectionBlocks
                  customFields={customFields}
                  handleCustomFieldChange={handleCustomFieldChange}
                  deleteCustomField={deleteCustomField}
                  addCustomField={addCustomField}
                  error={customFieldError}
                  setError={setCustomFieldError}
                  setCustomFieldLang={setCustomFieldLang}
                  customFieldLang={customFieldLang}
                  setCustomFields={handleDragnDropChange}
                  title={t('custom_fields.plural')}
                />
              )}
            </Card>
            <Card sectioned title={t('public_profile.background_image')} actions={[{ content: t('shared.change'), onAction: () => resetStaffBackgroundImage() }]}>
              {!staffBackgroundImage ? (
                <DropZone type="image" accept="image/*" allowMultiple={false} onDrop={handleStaffBackgroundImageDropZoneDrop}>
                  <DropZone.FileUpload />
                </DropZone>
              ) : uploadedStaffBackgroundImageFile}
              {staffBackgroundImage && (
                <TextField
                  value={publicProfile.staff_background_height?.toString()}
                  onChange={handleChange('staff_background_height')}
                  type="number"
                  helpText="Custom background image height"
                />
              )}
            </Card>
          </>
        )}
      </Tabs>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  isPremiumPlusMember: state.auth.premiumPlusMember,
  hasInvoiceAccess: state.auth.hasInvoiceAccess
});

const mapDispatchToProps = (dispatch) => ({
  updateUrlName: (urlName) => dispatch(actions.updateUrlName(urlName))
});

export default connect(mapStateToProps, mapDispatchToProps)(PublicProfileEdit);
